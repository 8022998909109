import createStyles from '@material-ui/core/styles/createStyles';

import type {WithStyles} from '@material-ui/core/styles/withStyles';

export const styles = () =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 8,
    },
    button: {
      minWidth: 'auto',
    },
  });

export type Styles = WithStyles<typeof styles>;
