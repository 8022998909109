import * as React from 'react';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

import {styles} from './style';

import type {FC} from 'react';
import type {Styles} from './style';
type Props = Styles & {
  batchIds: string;
  loading: boolean;
  disableAdd: boolean;
  batchMode: boolean;
  progress: number;
  onAddClick: () => void;
  onImportClick: () => void;
  onCancelClick: () => void;
};

const Footer: FC<Props> = ({
  classes,
  batchIds,
  loading,
  disableAdd,
  batchMode,
  progress,
  onImportClick,
  onAddClick,
  onCancelClick,
}: Props) => (
  <div className={classes.root}>
    {batchMode ? (
      batchIds === '' ? (
        <Button className={classes.button} variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
      ) : (
        <Button
          className={classes.button}
          variant="text"
          disabled={disableAdd || loading}
          onClick={onAddClick}
        >
          {loading ? `Progress ${progress}%` : 'Add'}
        </Button>
      )
    ) : (
      <Button className={classes.button} variant="text" onClick={onImportClick}>
        Batch import
      </Button>
    )}
  </div>
);

export default withStyles(styles)(Footer);
