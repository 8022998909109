export const aggsMediaNames = [
  'Bulletin',
  'Bulletins',
  'Posters',
  'Digital Posters',
  'Junior Posters',
  'Walls',
  'Spectacular',
  'Digital Walls',
  'Digital Displays',
  'Backlit Squares',
  'Banner',
  'Bike Share',
  'Digital Kiosk',
  'Digital Transit Shelter',
  'Flags',
  'Fullmotion Digital Urban Panel',
  'Kiosk',
  'Light Post Banner',
  'Phone Kiosk Vertical',
  'Shelter',
  'Static Digital Urban Panel',
  'Transit Shelters',
  'Urban Panels',
  'Barricade',
  'Trestle',
  'Tower',
];
