import * as React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {FilterActionPopper, RemovableList, ScrollbarsContext} from '@onsmart/ui-kit';
import classNames from 'classnames';
import equal from 'fast-deep-equal';

import {Form} from './Form';
import {mapDispatchToProps, mapStateToProps} from './selectors';
import {styles} from './styles';

import type {onCloseType, Option} from '@onsmart/ui-kit';
import type {MapDispatchToProps, MapStateToProps} from './selectors';
import type {Styles} from './styles';
const virtualized = {rowHeight: 38};

const toOption = (op: string) => ({label: op, value: op});

interface Props extends Styles, MapDispatchToProps, MapStateToProps {}

interface State {
  selected: string[];
}

class Popper extends Component<Props, State> {
  filterComponent: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      selected: props.value,
    };
  }

  handleSubmit = (ev: any) => {
    const {onChange} = this.props;
    const {selected} = this.state;

    onChange(selected, ev);
  };

  handleChange = (selected: string[]) => {
    const max = 10000;
    if (selected && selected.length > max) {
      this.props.showMessage('⚠️ Inventory ID limit is 10,000.');
    }
    this.setState({selected: (selected || []).slice(0, max)});
  };

  handleRemove = (value: Option[]) => {
    this.setState({selected: value.map((v) => v.label)});
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (!equal(nextProps.value, this.state.selected)) {
      this.setState({selected: nextProps.value});
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.state.selected !== prevState.selected &&
      (this.state.selected || []).length > (prevState.selected || []).length
    ) {
      this.scrollToBottom();
    }
  }

  private scrollToBottom() {
    if (this.filterComponent && this.filterComponent.scrollbars) {
      this.filterComponent.scrollbars.scrollToBottom(true);
    }
  }

  handleClose = (
    evt?: React.MouseEvent<HTMLElement> | React.ChangeEvent<{}>,
    type?: onCloseType,
  ) => {
    const {selected} = this.state;

    if (selected && selected.length > 0) {
      this.props.onPopperClose(selected);
    } else {
      this.props.resetTempValue();
      this.props.onPopperClose();
    }
  };

  render() {
    const {classes, anchorEl, open, hasChanges, loadSuggestions, resetTempValue} = this.props;
    const {selected = []} = this.state;

    const values = selected ? selected.map(toOption) : null;
    const showTotalMessageFrom = 1;
    const hasValues = values && !!values.length;
    // TODO: get header Height by ref;
    const HEADER_WITH_APP_BAR_SIZE = 108;
    const maxHeight = Math.ceil(window.innerHeight * 0.7 - HEADER_WITH_APP_BAR_SIZE);

    return (
      <FilterActionPopper
        maxHeight={maxHeight}
        innerRef={(ref) => (this.filterComponent = ref)}
        anchorEl={anchorEl}
        open={open}
        onClose={this.handleClose}
        headerTitle="Inventory ID"
        disabled={!hasValues}
        hasChanges={hasChanges}
        onCancelLabel="Reset"
        onCancel={hasChanges && resetTempValue}
        onSubmit={this.handleSubmit}
      >
        <div>
          <Form
            selected={selected}
            loadSuggestions={loadSuggestions}
            batchImport={this.props.batchImport}
            onChange={this.handleChange}
            allMarkets={this.props.allMarkets}
          />
          <ScrollbarsContext.Consumer>
            {({view}) => (
              <RemovableList
                classes={{
                  root: classNames(classes.listRoot, {[classes.emptyListRoot]: !hasValues}),
                }}
                virtualized={{...virtualized, scrollElement: view}}
                onChange={this.handleRemove}
                items={values}
                showTotalMessageFrom={showTotalMessageFrom}
              />
            )}
          </ScrollbarsContext.Consumer>
        </div>
      </FilterActionPopper>
    );
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Popper));
