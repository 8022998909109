import type {Action, Dispatch} from 'redux';
import {bindActionCreators as bindActions} from 'redux';

import {actions as globalActions} from 'config/redux/global/actions';

import type {RootState} from 'config/redux/rootReducer';
import type {Props} from '../InventoryIdFilter';

type ExternalProps = Pick<Props, 'onChange'> & {
  value: string[];
  open: boolean;
  anchorEl: HTMLElement;
  hasChanges: boolean;
  resetTempValue: () => void;
  onPopperClose: (temp?: string[]) => void;
  loadSuggestions: (search: string) => Promise<string[]>;
  batchImport: (ids: string[], markets: string[], mode: 'match' | 'exact') => Promise<string[]>;
};

export const mapStateToProps = (
  _state: RootState,
  external: ExternalProps = {} as ExternalProps,
) => ({
  allMarkets: _state.app.allMarkets,
  ...external,
});

export type MapStateToProps = ReturnType<typeof mapStateToProps>;

export const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  ...bindActions(
    {
      showMessage: globalActions.showMessage,
    },
    dispatch,
  ),
});

export type MapDispatchToProps = ReturnType<typeof mapDispatchToProps>;
