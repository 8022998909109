import * as React from 'react';
import {Component} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  AsyncAutocomplete,
  AutocompleteSize,
  FilterContext,
  PopperContentSection,
  TextArea,
  TextFieldSize,
} from '@onsmart/ui-kit';
import classNames from 'classnames';

import MarketFilter from 'components/common/filters/MarketFilter';
import {debounce} from 'utils/promise';

import {Footer} from '../Footer';
import {styles} from './styles';

import type {Option} from '@onsmart/ui-kit';
import type {Styles} from './styles';
import type {RootState} from 'config/redux/rootReducer';
const toOption = (op: string) => ({label: op, value: op});

export interface Props extends Styles {
  selected: string[];
  onChange: (filter: string[]) => void;
  loadSuggestions: (search: string) => Promise<string[]>;
  batchImport: (ids: string[], markets: string[], mode: 'match' | 'exact') => Promise<string[]>;
  allMarkets: RootState['app']['allMarkets'];
}

interface State {
  batchMode: boolean;
  batchIds: string;
  batchLoading: boolean;
  progress: number;
}

class Popper extends Component<Props, State> {
  static contextType = FilterContext;
  context: React.ContextType<typeof FilterContext>;

  debouncedLoad: () => Promise<unknown>;
  private _isMounted: boolean;

  constructor(props: Props) {
    super(props);
    this.state = {
      batchMode: false,
      batchLoading: false,
      batchIds: '',
      progress: 1,
    };
    this.debouncedLoad = debounce(this.loadMore, 500);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadMore = async (term = '') => {
    const ids = await this.props.loadSuggestions(term);

    const nonUsedIds = this.removeUsedKeywords(ids);
    return nonUsedIds.map(toOption);
  };

  removeUsedKeywords = (allOptions: string[]) => {
    const {selected} = this.props;
    const ids = selected || ([] as string[]);
    return allOptions.filter((id) => !ids.includes(id));
  };

  stillInBatchMode = () => {
    const {batchMode} = this.state;
    return batchMode && this._isMounted;
  };

  appendToSelected = (ids: string[]) => {
    const {onChange, selected = []} = this.props;
    const newSelected = new Set([...selected, ...ids]);
    onChange(Array.from(newSelected));
  };

  fetchBatchIds = async (candidates: string[], exact: boolean) => {
    const markets = this.context.shownFilters[MarketFilter.id];
    const mode = exact ? 'exact' : 'match';
    const batchIds = await this.props.batchImport(candidates, markets, mode);
    this.appendToSelected(batchIds);
  };

  handleAdd = async () => {
    this.setState({batchLoading: true});
    const {batchIds} = this.state;
    const candidates = batchIds
      .split(/\t|\n/)
      .map((x) => x.replace(/ /g, '').trim().toUpperCase())
      .filter((x) => !!x);

    const regex = `^(${this.props.allMarkets.map(({code}) => code).join('|')})`;
    const regExp = new RegExp(regex, 'i');

    const allStartWithMarketCode = candidates.every((candidate) => regExp.test(candidate));

    await this.fetchBatchIds(candidates, allStartWithMarketCode);

    if (this.stillInBatchMode()) {
      this.setState({batchMode: false, batchLoading: false, progress: 1, batchIds: ''});
    }
  };

  handleSelectChange = (value: Option) => {
    if (value) {
      const {selected = []} = this.props;
      this.props.onChange([...selected, value.label]);
    }
  };

  handleImportClick = () => {
    this.setState({batchMode: true});
  };

  handleCancelBatchClick = () => {
    this.setState({batchMode: false, progress: 1, batchLoading: false});
  };

  handleBatchIdsChange = (text: string) => {
    this.setState({batchIds: text});
  };

  render() {
    const {classes, selected} = this.props;
    const {batchMode, batchIds, batchLoading, progress} = this.state;

    return (
      <PopperContentSection
        className={classNames(classes.sticky, {
          [classes.stickyWithValues]: selected && !!selected.length,
        })}
      >
        {batchMode ? (
          <TextArea
            label="Inventory IDs (One per Line)"
            size={TextFieldSize.small}
            value={batchIds}
            fullWidth
            autoFocus
            disabled={batchLoading}
            rowsMax={4}
            onChange={this.handleBatchIdsChange}
          />
        ) : (
          <AsyncAutocomplete
            key={selected.length}
            size={AutocompleteSize.small}
            label="Search by Inventory ID"
            placeholder=""
            onChange={this.handleSelectChange}
            value={null}
            defaultOptions
            cacheOptions
            autoFocus
            loadOptions={this.debouncedLoad}
          />
        )}
        <Footer
          batchIds={batchIds}
          loading={batchLoading}
          progress={progress}
          disableAdd={!batchIds.trim().length}
          batchMode={batchMode}
          onAddClick={this.handleAdd}
          onCancelClick={this.handleCancelBatchClick}
          onImportClick={this.handleImportClick}
        />
      </PopperContentSection>
    );
  }
}

export default withStyles(styles)(Popper);
